import React from 'react';
import {
  FlexBox,
  Heading,
  UnorderedList,
  ListItem,
  FullScreen,
  AnimatedProgress,
  Appear,
  Slide,
  Deck,
  Text,
  Box,
  SlideLayout,
  Quote
} from 'spectacle';

import htm from 'htm';

function App() {

  const html = htm.bind(React.createElement);

  // SPECTACLE_CLI_THEME_START
  const theme = {
    fonts: {
      header: '"Open Sans Condensed", Helvetica, Arial, sans-serif',
      text: '"Open Sans Condensed", Helvetica, Arial, sans-serif'
    }
  };
  // SPECTACLE_CLI_THEME_END

  // SPECTACLE_CLI_TEMPLATE_START
  const template = () => html`
    <${FlexBox} justifyContent="space-between" position="absolute" bottom=${0} width=${1}>
      <${Box} padding="0 1em">
        <${FullScreen} />
      </${Box}>
      <${Box} padding="1em">
        <${AnimatedProgress} />
      </${Box}>
    </${FlexBox}>
  `;

  return (
    <Deck theme={theme} template={template}>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading margin="0px" fontSize="150px">✨<i>Hello World</i> ✨</Heading>
          <Heading margin="0px" color="white" fontSize="h2">Think Like a Software Engineer</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading margin="-8px" fontSize="150px">🧑🏻‍💻</Heading>
          <Heading margin="4px" padding="-4px" fontSize="h2">Moh Abdul Ghofur Afifudin Zein</Heading>
          <Text margin="-2px" padding="-2px">Software Engineer</Text>
          <Text margin="4px" padding="-2px">gmail@magazzein.com</Text>
          <Text margin="4px" padding="-2px">085859988939</Text>
        </FlexBox>
      </Slide>
      <Slide>
        <Heading fontSize="h2">💼 Working Experiences</Heading>
        <UnorderedList>
          <Appear>
            <ListItem>
              <Text>Business Analyst Intern - UNAIR</Text>
            </ListItem>
          </Appear>
          <Appear>
            <ListItem>
              <Text>Backend Developer - Twiscode</Text>
            </ListItem>
          </Appear>
          <Appear>
            <ListItem>
              <Text>Software Engineer - Qiscus</Text>
            </ListItem>
          </Appear>
          <Appear>
            <ListItem>
              <Text>Software Engineer - Tokopedia</Text>
            </ListItem>
          </Appear>
        </UnorderedList>
      </Slide>
      <SlideLayout.Quote attribution=" Steve Jobs">
        <Quote>Everyone in this country should learn how to program because it teaches you how to think</Quote>
      </SlideLayout.Quote>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Understand the problem</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Breakdown the problem</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Think systematically</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Pattern</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Efficiency</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading fontSize="h2">Innovative</Heading>
        </FlexBox>
      </Slide>
      <Slide>
        <FlexBox height="100%" flexDirection="column">
          <Heading margin="0px" fontSize="150px">🤷🏻‍♂️</Heading>
          <Heading margin="0px" fontSize="h2">Any Question?</Heading>
        </FlexBox>
      </Slide>
    </Deck>
  );
}

export default App;
